import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ContactSectionNavigationsWhite3 from '../components/navigations-white/ContactSectionNavigationsWhite3';
import ContactSectionContactsWhitePattern1 from '../components/contacts-white-pattern/ContactSectionContactsWhitePattern1';
import ContactSectionFootersWhitePattern2 from '../components/footers-white-pattern/ContactSectionFootersWhitePattern2';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Contact() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <ContactSectionContactsWhitePattern1 />
      {/* <ContactSectionFootersWhitePattern2 /> */}
    </React.Fragment>
  );
}

