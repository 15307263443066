import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <>
        <div>
          <section
            className="bg-white"
            style={{
              backgroundImage:
                'url("flex-ui-assets/elements/pattern-white.svg")',
              backgroundPosition: "center",
            }}
          >
            <div className="container px-4 mx-auto">
              <div className="pt-24 pb-11 mx-auto max-w-4xl">
                <a className="block md:mx-auto mb-5 max-w-max" href="/">
                  <img
                    className="lg:h-28 h-20"
                    src="https://static.shuffle.dev/uploads/files/b8/b863891aa99406bca42fc41ab70471d0c7b2763e/DARWIN-logo-with-text.png"
                    alt
                  />
                </a>
                <div className="flex flex-wrap justify-center -mx-3 lg:-mx-6">
                  <div className="w-full md:w-auto p-3 md:px-6">
                  <Link
                  to="/"
                  className="text-coolGray-500 hover:text-coolGray-900 font-medium"
                >
                  Home
                </Link>
                  </div>
                  <div className="w-full md:w-auto p-3 md:px-6">
                  <Link
                  to="/contact"
                  className="text-coolGray-500 hover:text-coolGray-900 font-medium"
                >
                  Contact
                </Link>
                  </div>
                  <div className="w-full md:w-auto p-3 md:px-6">
                  <Link
                  to="/openings"
                  className="text-coolGray-500 hover:text-coolGray-900 font-medium"
                >
                  Career
                </Link>
                  </div>
                  <div className="w-full md:w-auto p-3 md:px-6">
                  <Link
                  to="/application"
                  className="text-coolGray-500 hover:text-coolGray-900 font-medium"
                >
                  Application
                </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b border-coolGray-100" />
            <div className="container px-4 mx-auto">
              <p className="py-10 md:pb-20 text-lg md:text-xl text-coolGray-400 font-medium text-center">
                © 2024 DARWIN LOGISTICS. All rights reserved.
              </p>
            </div>
          </section>
        </div>
      </>
    </React.Fragment>
  )
}

export default Footer